import React from "react"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { H1, Paragraph, ContentContainer, OrderedList, ListItem, ExternalLink } from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Organisation" />
      <Layout>
        <ContentContainer>
          <H1>Organisation</H1>
          <Paragraph>Vår förskola är en ekonomisk förening i form av ett föräldrakooperativ, vilket innebär att föräldrarna gemensamt äger och driver förskolan. Det är en privat förskola som drivs med bidrag från kommunen. Förskolan har en styrelse vilken är det beslutande organet och fungerar som arbetsgivare för den anställda personalen.</Paragraph>
          <Paragraph>Kommunen har full insyn i verksamheten och det ställs samma kvalitetskrav på ett kooperativ som på de kommunala förskolorna. Kommunen genomförde senast en tillsyn av verksamheten 2018.</Paragraph>
          <Paragraph>Organisationen kan beskrivas enligt följande:</Paragraph>
          <OrderedList>
            <ListItem>Föreningsstämman</ListItem>
            <ListItem>Styrelsen</ListItem>
            <ListItem>Förskolechef</ListItem>
            <ListItem>Personalen</ListItem>
            <ListItem>Föräldrar</ListItem>
          </OrderedList>
          <Paragraph>Formellt betyder det att förskolechefen är ansvarig för verksamheten men underställd styrelsen. Föräldrar är underställda personalen vid insats i verksamheten.</Paragraph>
          <Paragraph>Vissa föräldrakooperativ kan äga sin fastighet, vi äger dock inte förskolans lokaler utan hyr dem av en fastighetsägare.</Paragraph>
          <Paragraph>Är du nyfiken på att veta mer om hur en ekonomisk förening fungerar finns mycket bra information på <ExternalLink href="http://www.bolagsverket.se/fo/foreningsformer/ekonomisk">Bolagsverket</ExternalLink>.</Paragraph>
        </ContentContainer>
      </Layout>
    </>
  )
}



export default Page
